import React from 'react';

import Layout from '@components/layout';

const StyleGuide = () => {
  return (
    <Layout>
      <div className="p-20 grid gap-y-10">
        <h1>Heading 1</h1>
        <h2>Heading 2</h2>
        <h3>Heading 3</h3>
        <h4>Heading 4</h4>
        <h5>Heading 5</h5>
        <h4 className="is-subtitle">Subtitle</h4>
      </div>
    </Layout>
  );
};

export default StyleGuide;
